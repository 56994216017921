import Vue from 'vue'
// const components = require.context('../common', true, /\.vue$/);
import mUplodaer from './m-uploader'
import mHeader from './m-header'
import mSearch from './m-search'
import mTable from './m-table'
import editor from './editor'
import LatLng from './LatLng'
import preImages from './preImages'
import mCropper from './m-cropper'
import mUploader from './m-uploader'

Vue.component('m-uploader', mUplodaer)
Vue.component('m-header', mHeader)
Vue.component('m-search', mSearch)
Vue.component('m-table', mTable)
Vue.component('editor', editor)
Vue.component('LatLng', LatLng)
Vue.component('preImages', preImages)
Vue.component('m-cropper', mCropper)
Vue.component('m-uploader', mUploader)

// //全局注册组件
// components.keys().forEach(filename => {
//     let arr = filename.replace(/\.\//, '').replace(/\.vue$/, '').split('/');
//     let componentName = arr[arr.length - 1];
//     let componentConfig = components(filename);
//     console.log({ componentName })
//     console.log()
//     Vue.component(componentName, componentConfig);
// })

// export default {
//     install(Vue) {
//         ///全局注册组件
//         components.keys().forEach(filename => {
//             let arr = filename.replace(/\.\//, '').replace(/\.vue$/, '').split('/');
//             let componentName = arr[arr.length - 1];
//             let componentConfig = components(filename);
//             console.log({ componentConfig })
//             Vue.component(componentName, componentConfig);
//         })
//     }
// }
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import iView from 'iview'
import ElementUI from 'element-ui';
import { appRouter } from '@/router/appRouter'
import $axios from '@/tool/axios.js'
import Directives from '@/directives/index'
import dataV from '@jiaminghi/data-view'
import common from '@/common/index.js';
import 'iview/dist/styles/iview.css'
import 'element-ui/lib/theme-chalk/index.css';
import 'bulma/css/bulma.css'
import '@/styles/css/common.css'
import '@/styles/css/normalize.css'
import tools from '@/tool/tools'

import VueDND from 'awe-dnd'
Vue.config.productionTip = false

Vue.use(iView);
Vue.use(ElementUI);
Vue.use(VueDND)
Vue.use(Directives)
Vue.use(dataV)
Vue.prototype.$axios = $axios;
Vue.prototype.$tools = tools;
// 修改 el-dialog 默认点击遮照为不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false

router.beforeEach(function (to, from, next) {
  let cachePage = JSON.parse(localStorage.cachePage || '[]');
  cachePage = [...new Set([to.name, ...cachePage])];
  localStorage.cachePage = JSON.stringify(cachePage);
  store.commit('initCachepage');
  next();
});

new Vue({
  router,
  store,
  render: h => h(App),
  data: {
    currentPageName: ''
  },
  mounted() {
    this.currentPageName = this.$route.name;
    this.$store.commit('initCachepage');
    // 权限菜单过滤相关
    this.$store.commit('updateMenulist');
    // 全屏相关
    document.addEventListener('fullscreenchange', () => {
      this.$store.commit('changeFullScreenState');
    });
    document.addEventListener('mozfullscreenchange', () => {
      this.$store.commit('changeFullScreenState');
    });
    document.addEventListener('webkitfullscreenchange', () => {
      this.$store.commit('changeFullScreenState');
    });
    document.addEventListener('msfullscreenchange', () => {
      this.$store.commit('changeFullScreenState');
    });
  },
  created() {
    console.log('env', process.env.NODE_ENV)
    if (process.env.NODE_ENV !== 'development') {
      this.$store.state.uploadUrl = '/teaCircleParty/api/upload/unCheck'
    } else {
      this.$store.state.uploadUrl = '/api/upload/unCheck'
    }
    let tagsList = [];
    appRouter.map((item) => {
      if (item.children.length <= 1) {
        tagsList.push(item.children[0]);
      } else {
        tagsList.push(...item.children);
      }
    });
    this.$store.commit('setTagsList', tagsList);
  }
}).$mount('#app')

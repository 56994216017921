<template>
  <div class="main" :class="{ 'main-hide-text': hideMenuText }">
    <div
      class="main-header-con"
    >
      <div class="main-header">
        <div class="header-left">
            <img src="../assets/images/logo.png" class="mian-logo" alt="">
            <div class="system-name">芳村茶叶商圈党委</div>
        </div>
        <!-- <div class="navicon-con">
          <Button
            :style="{
              transform:
                'rotateZ(' + (this.hideMenuText ? '-90' : '0') + 'deg)',
            }"
            type="text"
            @click="toggleClick"
          >
            <Icon type="navicon" size="32"></Icon>
          </Button>
        </div> -->
        <div class="header-avator-con hide-mobile">
          <div
            @click="handleFullScreen"
            v-if="showFullScreenBtn"
            class="full-screen-btn-con"
          >
            <Tooltip
              :content="isFullScreen ? '退出全屏' : '全屏'"
              placement="bottom"
            >
              <Icon
                :type="isFullScreen ? 'arrow-shrink' : 'arrow-expand'"
                :size="23"
              ></Icon>
            </Tooltip>
          </div>
          <div class="user-dropdown-menu-con">
            <Row
              type="flex"
              justify="end"
              align="middle"
              class="user-dropdown-innercon"
            >
              <Dropdown trigger="click" @on-click="handleClickUserDropdown">
                <a href="javascript:void(0)">
                  <span class="main-user-name">{{ user.username }}</span>
                  <Icon type="arrow-down-b"></Icon>
                </a>
                <DropdownMenu slot="list">
                  <DropdownItem name="loginout">退出登录</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Row>
          </div>
        </div>
      </div>
      <!-- <div class="tags-con">
        <tags-page-opened :pageTagsList="pageTagsList"></tags-page-opened>
      </div> -->
    </div>
    <div class="main-content">
        <div
      :style="{ width: hideMenuText ? '0' : '200px' }"
      class="sidebar-menu-con"
    >
      <sidebar-menu :menuList="menuList" :iconSize="20" />
    </div>
      <div
        id="single-page-con"
        class="single-page-con"
        :style="{ left: hideMenuText ? '0' : '200px' }"
      >
        <div class="single-page">
          <!-- <keep-alive :include="cachePage"> -->
            <router-view></router-view>
          <!-- </keep-alive> -->
        </div>
      </div>
      <up-down srcollEl="#single-page-con"></up-down>
    </div>
  </div>
</template>
<script>
import sidebarMenu from "./main_components/sidebarMenu.vue";
import tagsPageOpened from "./main_components/tagsPageOpened.vue";
import upDown from "@/common/upDown.vue";
import util from "@/libs/util.js";

import Admin from "../axios/api/admin";

import { otherRouter, appRouter } from "@/router/router";
export default {
  components: {
    sidebarMenu,
    tagsPageOpened,
    upDown,
  },
  data() {
    return {
      user: {},
      spanLeft: 4,
      spanRight: 20,
      currentPageName: "",
      hideMenuText: false,
      showFullScreenBtn: window.navigator.userAgent.indexOf("MSIE") < 0,
      messageCount: 0,
      lockScreenSize: 0,
      buttonPermissionList: [],
      menuList: [],
    };
  },
  computed: {
    // menuList() {

    //     // return this.$store.state.menuList || [];
    //     return appRouter
    // },

    tagsList() {
      return this.$store.state.tagsList; // 所有页面的页面对象
    },

    pageTagsList() {
      return this.$store.state.pageOpenedList; // 打开的页面的页面对象
    },

    currentPath() {
      return this.$store.state.currentPath; // 当前面包屑数组
    },

    menuIconColor() {
      return this.$store.state.menuTheme === "dark" ? "white" : "#495060";
    },

    cachePage() {
      return this.$store.state.cachePage;
    },

    isFullScreen() {
      return this.$store.state.isFullScreen;
    },
  },
  methods: {
    init() {
      this.$store.commit("setCurrentPageName", this.$route.name);
      let pathArr = util.setCurrentPath(this, this.$route.name);
      if (pathArr.length >= 2) {
        this.$store.commit("addOpenSubmenu", pathArr[1].name);
      }
    },

    toggleClick() {
      this.hideMenuText = !this.hideMenuText;
    },

    handleClickUserDropdown(name) {
      let that = this;
      if (name === "loginout") {
        Admin.logout().then(() => {
          that.$store.commit("clearOpenedSubmenu");
          localStorage.removeItem("user");
          that.$router.push("/login");
        });
      }
    },

    handleFullScreen() {
      this.$store.commit("handleFullScreen");
    },

    checkTag(name) {
      let openpageHasTag = this.pageTagsList.some((item) => {
        if (item.name === name) {
          return true;
        }
      });
      if (!openpageHasTag) {
        //  解决关闭当前标签后再点击回退按钮会退到当前页时没有标签的问题
        util.openNewPage(
          this,
          name,
          this.$route.params || {},
          this.$route.query || {}
        );
      }
    },

    //获取当前用户信息
    // getCurrentUser() {
    //     const that = this;
    //     Admin.prototype.getCurrentInfo().then(
    //         res => {
    //             let menuList = this.$store.state.menuList
    //             that.user = res.data.data || {};
    //             menuList = that.processList(menuList,res.data.extra.listAllPermission)
    //             console.log("menuList", menuList)
    //             this.$store.state.menuList = menuList
    //             this.menuList = menuList
    //         },
    //         () => {
    //             localStorage.removeItem('user');
    //             location.href = `#/login?returnUrl=${location.href}`;
    //         }
    //     );
    // },

    
    /***
     * 将 扁平化数组 转化成 层级数组
     * @param data 数组
     * @param id  唯一值的key值
     * @param pid 父id的key值
     * @returns {[]}
     */
    treeDataTranslate(data, id = "id", pid = "parentId") {
      let res = [];
      let temp = {};
      for (let i = 0; i < data.length; i++) {
        temp[data[i][id]] = data[i];
      }
      for (let k = 0; k < data.length; k++) {
        if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
          if (!temp[data[k][pid]]["children"]) {
            temp[data[k][pid]]["children"] = [];
          }
          if (!temp[data[k][pid]]["_level"]) {
            temp[data[k][pid]]["_level"] = 1;
          }
          data[k]["_level"] = temp[data[k][pid]]._level + 1;
          temp[data[k][pid]]["children"].push(data[k]);
        } else {
          res.push(data[k]);
        }
      }
      return res;
    },

    //整理列表
    processList(list, permissionList) {
      let navList = this.treeDataTranslate(permissionList);
      this.getButtonPermission(navList);
      this.navList = navList;
      console.log(navList);

      console.log("权限");
      let arr = [];
      console.log(list);
      console.log(permissionList);
      list.forEach((first) => {
        permissionList.forEach((second) => {
          if (first.permission === second.permission) {
            arr.push({
              ...first,
            });
          }
        });
      });
      console.log(arr);
      arr.forEach((first) => {
        let childArr = [];
        if (first.children) {
          first.children.forEach((child) => {
            for (let i = 0; i < permissionList.length; i++) {
              if (permissionList[i].type === 3) {
                if (
                  child.permission ===
                  permissionList[i].permission +
                    "|" +
                    permissionList[i].requestMethod
                ) {
                  childArr.push({
                    ...child,
                  });
                  continue;
                }
              }
            }
          });
        }
        console.log(childArr);
        if (childArr.length > 0) {
          first.children = childArr;
        } else {
          first.children = [];
        }
      });
      console.log(arr);
      return arr;
    },
    getButtonPermission(list) {
      console.log("获取权限列表", list);
      let arr = list.filter((item) => {
        return item.permission === "/zhaozhaocha";
      });
      this.dealList(arr);

      localStorage.setItem(
        "zhaozhaocha_button_permission",
        JSON.stringify(this.buttonPermissionList)
      );
    },
    dealList(list) {
      console.log(list, "权限列表");
      list.forEach((first) => {
        if (!first.children) {
          if (first.type === 4) {
            this.buttonPermissionList.push(
              first.permission + "|" + first.requestMethod
            );
          }
        } else {
          this.dealList(first.children);
        }
      });
    },
    
    //获取当前用户信息
    getCurrentUser() {
      const that = this;
      Admin.getCurrentInfo().then(
        (res) => {
          if (res.data.data == null) {
            this.$router.push({ path: "/login" });
          } else {
            let permission = res.data.data.roles[0].permissions;
            console.log(permission);
            this.dealMenuList(permission);
          }

          // let user = res.data.data || {};
          // that.user = user;
          // localStorage.setItem('user', JSON.stringify(user));
        },
        () => {
          localStorage.removeItem("user");
          location.href = `#/login?returnUrl=${location.href}`;
        }
      );
    },
    dealMenuList(permission) {
      let menuList = this.$store.state.menuList;
      console.log(menuList);
      menuList.forEach((item) => {
        if (item.children.length > 0) {
          item.children.forEach((s) => {
            s.show = false;
          });
        } else {
          item.show = false;
        }
      });
      console.log(permission);
      permission.forEach((item) => {
        menuList.forEach((item1, index) => {
          if (item1.children.length > 0) {
            item1.show = true;
            item1.children.forEach((s) => {
              let name = s.path.split("/")[0];
              // if (item.permission === name) { // 临时注释
              s.show = true;
              // }
            });
          } else {
            item1.show = true;
          }
        });
      });
      menuList.forEach((item) => {
        let num = 0;
        item.children.forEach((item1) => {
          if (item1.show) {
            num++;
          }
        });
        if (num === 0) {
          item.show = false;
        }
      });
      this.menuList = menuList;

      console.log(menuList);
    },
  },

  watch: {
    $route(to) {
      this.$store.commit("setCurrentPageName", to.name);
      let pathArr = util.setCurrentPath(this, to.name);
      if (pathArr.length > 2) {
        this.$store.commit("addOpenSubmenu", pathArr[1].name);
      }
      this.checkTag(to.name);
    },
    
  },
  mounted() {},

  created() {
    this.getCurrentUser();
    // this.dealMenuList(["operation"]); // 这个只是临时的，上权限后换this.getCurrentUser()
    this.user = JSON.parse(localStorage.getItem("user")) || {};
    // this.hideMenuText = document.documentElement.clientWidth <= 768;
    this.$store.commit("changeMenuTheme", "dark");
    this.$store.commit("changeMainTheme", "b");
    this.$store.commit("setOpenedList");
  },
};
</script>

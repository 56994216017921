var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"scrollCon",staticClass:"tags-outer-scroll-con",on:{"mousewheel":_vm.handlescroll}},[_c('div',{ref:"scrollBody",staticClass:"tags-inner-scroll-body",style:({ left: _vm.tagBodyLeft + 'px' })},[_c('transition-group',{attrs:{"name":"taglist-moving-animation"}},[(_vm.pageTagsList.length)?_vm._l((_vm.pageTagsList),function(item){return _c('Tag',{key:item.name,ref:"tagsPageOpened",refInFor:true,attrs:{"type":"dot","name":item.name,"closable":item.name === 'tea-account-list' ? false : true,"color":item.children
              ? item.children[0].name === _vm.currentPageName
                ? 'blue'
                : 'default'
              : item.name === _vm.currentPageName
              ? 'blue'
              : 'default'},on:{"on-close":_vm.closePage},nativeOn:{"click":function($event){return _vm.linkTo(item)}}},[_vm._v(" "+_vm._s(_vm.itemTitle(item))+" ")])}):_vm._e()],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
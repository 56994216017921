<template>
   <header class="y-center" style="background: none;padding:16px 0;">
      <div class="size-md bolder" style="margin-right: 10px" v-if="title">{{title}}</div>
      <div>
        <slot></slot>
      </div>
    </header>
</template>

<script>
export default {
    props:{
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style>

</style>
import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import mutations from "./mutations";
import actions from "./actions"

Vue.use(Vuex);

const store = new Vuex.Store({
    // strict: process.env.NODE_ENV !== "production",
    state: {
        brandList: [],
        openedSubmenuArr: [],
        uploadUrl: (()=> {
							if (process.env.NODE_ENV !== 'development') {
								return '/teaCircleParty/api/upload/unCheck'
							}
							return '/api/upload/unCheck'
            })()
    },
    mutations,
    actions,

});

export default store;

<template>
  <div class="search">
    <el-form
      :inline="true"
      ref="ruleForm"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-form-item
        v-for="(item, index) in searchItemList"
        :key="index"
        :label="item.label"
      >
        <!-- 输入框 -->
        <el-input
          v-if="item.type == 'input'"
          v-model="formInline[item.param]"
          size="small"
          placeholder="请输入"
          @keyup.enter.native="onSubmit"
        ></el-input>

        <!-- 单选框 -->
        <el-select
          v-if="item.type == 'select'"
          v-model="formInline[item.param]"
          filterable
          placeholder="请选择"
          size="small"
          clearable
          @change="onSubmit"
        >
          <el-option
            v-for="(item2, index2) in item.selectOptions"
            :key="index2"
            :label="item2[item.keyName || 'name']"
            :value="item2[item.keyValue || 'id']"
          ></el-option>
        </el-select>

        <!-- 时间区间选择 -->
        <!-- value-format 是element-ui的输出日期格式，默认是时间戳 -->
        <el-date-picker
          v-if="item.type == 'datetimerange'"
          v-model="formInline[item.param]"
          type="datetimerange"
          :value-format="item.valueFormat || 'timestamp'"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          size="small"
           @keyup.enter.native="onSubmit"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item style="width: 10rem">
        <el-button type="primary" plain  @click="onSubmit" v-if="!screen">查询</el-button>
        <el-button type="primary" plain  @click="onSubmit"  v-if="screen">筛选</el-button>
        <el-button type=""  @click="resetForm('ruleForm')"
          >重置</el-button
        >
      </el-form-item>

      <!-- 可用于显示其他按钮 -->
      <slot></slot>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    emitSearch: {
      // 是否立即执行搜索
      type: Boolean,
      default: false,
    },
    screen:{
      type:Boolean,
      default:false
    },
    keyName: {
      type: String,
      default: 'name'
    },
    keyValue: {
      type: String,
      default: 'id'
    },
    searchItemList: {
      type: Array,
      default() {
        return [
          {
            label: "下拉框",
            type: "select",
            selectOptions: [{ name: 111, value: 111 }],
            param: "company",
            defaultSelect: "222", // 下拉框默认选中项
          },
          {
            label: "输入框",
            type: "input",
            param: "name",
          },
        ];
      },
    },
  },
  data() {
    let formInline = {};
    for (const obj of this.searchItemList) {
      formInline[obj.param] = obj.defaultSelect || "";
    }
    return {
      formInline,
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value1: [],
        value2: ''
    };
  },
  watch: {
    emitSearch(newVal, oldVal) {
      // 是否立即触发搜索  用在弹窗中异步请求下拉框后  或者给下拉框赋值默认值后  需要用到这个方法
      if (newVal) {
        console.log("此时触发--立即执行搜索");
        this.$emit("search", this.formInline);
      }
    },
    searchItemList: {
      handler(newVal, oldVal) {
        for (const obj of this.searchItemList) {
          console.log(this.formInline,'aaaaaa');
          console.log(obj.param,'aaaaaa');
          if (obj.defaultSelect) {
            formInline[obj.param] = obj.defaultSelect;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    onSubmit() {
      // console.log('submit!',this.formInline);
      console.log(this.formInline);
      this.$emit("on-search-submit", this.formInline);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      let formInline = {};
      for (const obj of this.searchItemList) {
        // formInline[obj.param] = obj.defaultSelect || "";  // 重置时下拉框的默认值如果要保留就选用这个
        formInline[obj.param] = ""; // 所有筛选条件清空
      }
      this.formInline = formInline;

      this.$emit("search", this.formInline);
    },
  },
};
</script>

<style lang="less" scoped>
.el-form-item {
  margin-bottom: 5px;
}
.search{
    padding:20px;
    background: #F7F8FA;
    border: 2px;
    padding-bottom: 10px;
}
</style>
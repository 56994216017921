import Main from '@/views/Main.vue';

// 作为Main组件的子页面展示但是不在左侧菜单显示的路由写在otherRouter里
export const otherRouter = {
  path: '/',
  name: 'otherRouter',
  redirect: '/login',
  component: Main,
  children: [
    {
      path: 'activity/edit',
      title: '新增活动',
      name: 'activity-edit',
      component: resolve => {
          require(['../pages/activity/edit.vue'], resolve);
      }
    },

    {
      path: 'partylist/addBranch',
      title: '新增支部',
      name: 'addBranch',
      component: resolve => {
          require(['../pages/partylist/addBranch.vue'], resolve);
      }
    },

    {
      path: 'info/edit',
      title: '资讯编辑',
      name: 'parameter-list',
      permission: '/zhaozhaocha/platform',
      component: resolve => {
          require(['../pages/info/edit.vue'], resolve);
      }
    },
    {
      path: 'market/edit',
      title: '编辑市场',
      name: 'parameter-list',
      permission: '/zhaozhaocha/platform',
      component: resolve => {
        require(['../pages/market/edit.vue'], resolve);
      }
    },
    {
      path: 'user/edit',
      title: '编辑用户',
      name: 'parameter-list',
      permission: '/zhaozhaocha/platform',
      component: resolve => {
        require(['../pages/user/edit.vue'], resolve);
      }
    },
  ]
}

import Main from '@/views/Main.vue';

// 作为Main组件的子页面展示并且在左侧菜单显示的路由写在appRouter里
// 如果想保持组件keep-alive，组件名和name字段保持一致
export const appRouter = [
    {
        path: '',
        icon: 'android-folder',
        name: 'operation', // 权限是和名字一样
        title: '资讯管理',
        role: 'admin',
        permission: '/zhaozhaocha/platform',
        component: Main,
        children: [{
            path: 'info/list',
            title: '资讯列表',
            name: 'parameter-list',
            permission: '/zhaozhaocha/platform',
            component: resolve => {
                require(['../pages/info/list.vue'], resolve);
            }
        },
        ]
    },

    {
        path: '',
        icon: 'android-folder',
        name: 'partylist', // 权限是和名字一样
        title: '党支部管理',
        role: 'admin',
        permission: '/zhaozhaocha/platform',
        component: Main,
        children: [{
            path: 'partylist/partylist',
            title: '支部列表',
            name: 'parameter-list',
            permission: '/zhaozhaocha/platform',
            component: resolve => {
                require(['../pages/partylist/partylist.vue'], resolve);
            }
        },
            // {
            //     path: 'info/addBranch1',
            //     title: '支部列表',
            //     name: 'parameter-list',
            //     permission: '/zhaozhaocha/platform',
            //     component: resolve => {
            //         require(['../pages/partylist1/addBranch1.vue'], resolve);
            //     }
            // },
        ]
    },

    {
        path: '',
        icon: 'android-folder',
        name: 'operation', // 权限是和名字一样
        title: '活动管理',
        role: 'admin',
        permission: '/zhaozhaocha/platform',
        component: Main,
        children: [{
            path: 'activity/list',
            title: '活动列表',
            name: 'activity-list',
            permission: '/zhaozhaocha/platform',
            component: resolve => {
                require(['../pages/activity/list.vue'], resolve);
            }
        },
        ]
    },

    {
        path: '',
        icon: 'android-folder',
        name: 'operation', // 权限是和名字一样
        title: '市场管理',
        role: 'admin',
        permission: '/zhaozhaocha/platform',
        component: Main,
        children: [{
            path: 'market/list',
            title: '市场列表',
            name: 'market-list',
            permission: '/zhaozhaocha/platform',
            component: resolve => {
                require(['../pages/market/list.vue'], resolve);
            }
        },
        ]
    },

    {
        path: '',
        icon: 'android-folder',
        name: 'operation', // 权限是和名字一样
        title: '用户管理',
        role: 'admin',
        permission: '/zhaozhaocha/platform',
        component: Main,
        children: [{
            path: 'user/list',
            title: '账号列表',
            name: 'user-list',
            permission: '/zhaozhaocha/platform',
            component: resolve => {
                require(['../pages/user/list.vue'], resolve);
            }
        },
        ]
    },
];

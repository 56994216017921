import qs from 'qs';
import iView from 'iview';
import axios from 'axios';
import CryptoJS from '@/tool/crypto';

export default function(config) {
    let sid = localStorage.getItem('token')
    console.log('process.env.NODE_ENV', process.env.NODE_ENV)
    if (process.env.NODE_ENV !== 'development') {
        config.url = '/teaCircleParty' + config.url
    }
    if (config.params) {
        config.params.sid = sid
    }else {
        config.params = {
            sid
        }
    }
    if (config.isFormData) {
        config.data = qs.stringify(config.data);
        !config.headers && (config.headers = {});
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    if (config.isUpload) {
        !config.headers && (config.headers = {});
        config.headers['Content-Type'] = 'multipart/form-data';
    }

    return axios(config).then(
        res => {

            if (res.data && typeof(res.data) === 'string') {
                res.data = JSON.parse(CryptoJS.Decrypt(res.data))
            }

            if (res.data.status >= 1 && res.data.status !== 401) {
                config.showSuccess && iView.Notice.success({
                    duration: 2,
                    title: '通知',
                    desc: res.data.msg
                });
                return Promise.resolve(res);
            }
            console.log(res)
            if (res.data.status === 0) {
                !config.hiddenError && iView.Notice.error({
                    duration: 2,
                    title: '提示',
                    desc: res.data.msg
                });
                return Promise.reject(res);
            }

            if (res.data.status === 401) {
                iView.Notice.error({
                    duration: 2,
                    title: '提示',
                    desc: '您没有这个权限！'
                });
            }

            if (res.data.status < 0) {
                location.href = `#/login?returnUrl=${location.href}`;
                localStorage.removeItem('user');
                return Promise.reject(res);
            }
            return Promise.resolve(res);
        }
    );
}

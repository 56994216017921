<style scoped>
    .ivu-icon {
        vertical-align: text-bottom;
    }
</style>
<template>
    <!-- <Menu
      ref="sideMenu"
      :active-name="$route.name"
      :open-names="openedSubmenuArr"
      :theme="$store.state.menuTheme"
      width="auto"
      @on-select="changeMenu"

    >-->
    <Menu
            ref="sideMenu"
            :open-names="openedSubmenuArr"
            theme="dark"
            width="auto"
            @on-select="changeMenu"
    >
        <div v-for="(item, i) in menuList" :key="item.id">
            <!--      <MenuItem v-if="item.children.length === 1" :name="i + '-0'" :to="item.path + '/' + item.children[0].path">-->
            <!--        <Icon :type="item.icon" :size="iconSize"></Icon>-->
            <!--        <span class="layout-text">{{ itemTitle(item) }}</span>-->
            <!--      </MenuItem>-->

            <Submenu :name="i">
                <template slot="title">
                    <Icon :type="item.icon" :size="iconSize"></Icon>
                    <span class="layout-text">{{ itemTitle(item) }}</span>
                </template>
                <div v-for="(child, j) in item.children" :key="child.id">
                    <MenuItem :name="i + '-' + j" :to="item.path + '/' + child.path" v-if="!child.children">
                        <Icon :type="child.icon" :size="iconSize"></Icon>
                        <span class="layout-text">{{ child.title }}</span>
                    </MenuItem>
                    <Submenu :name="i + '-' + j" v-else>
                        <template slot="title">
                            <Icon :type="child.icon" :size="iconSize"></Icon>
                            <span class="layout-text">{{ child.title }}</span>
                        </template>
                        <div
                                v-for="(son, k) in child.children"
                                :to="item.path + '/' + child.path + '/' + son.path"
                                :key="son.id"
                        >
                            <MenuItem :name="i + '-' + j + '-' + k">{{ son.title }}</MenuItem>
                        </div>
                    </Submenu>
                </div>
            </Submenu>
        </div>
    </Menu>
</template>

<script>
    import util from "@/libs/util";
    import Vue from "vue";

    export default {
        data() {
            return {
                openedSubmenuArr: this.$store.state.openedSubmenuArr,
            };
        },
        name: "sidebarMenu",
        props: {
            slotTopClass: String,
            menuList: Array,
            iconSize: Number,
        },
        computed: {
            tagsList() {
                return this.$store.state.tagsList;
            },
        },
        methods: {
            changeMenu(active) {
                console.log(
                    "active",
                    active,
                    this.$route.name,
                    this.openedSubmenuArr,
                    this.$store.state.menuTheme
                );
                console.log(this.menuList);
                let arr = active.split("-");
                if (arr.length === 2) {
                    this.$router.push({
                        path:
                            this.menuList[arr[0]].path +
                            "/" +
                            this.menuList[arr[0]].children[arr[1]].path,
                    });
                } else if (arr.length === 3) {
                    this.$router.push({
                        path:
                            this.menuList[arr[0]].path +
                            "/" +
                            this.menuList[arr[0]].children[arr[1]].path +
                            this.menuList[arr[0]].children[arr[1]].children[arr[2]].path,
                    });
                }
                // if (active !== "accesstest_index") {
                //   util.openNewPage(this, active);
                //   this.$router.push({
                //     name: active,
                //   });
                // }
                // this.$router.push({
                //   path: active
                // })
            },
            itemTitle(item) {
                if (typeof item.title === "object") {
                    return item.title;
                } else {
                    return item.title;
                }
            },
        },
        watch: {
            $route(to) {
                localStorage.currentPageName = to.name;
            },
            currentPageName() {
                this.openedSubmenuArr = this.$store.state.openedSubmenuArr;
            },
        },
    };
</script>
<style scoped>
</style>


import {otherRouter, appRouter} from '@/router/router';
import {router} from '@/router/index';
const state = {
    prefix: '',
    routers: [
        otherRouter,
        ...appRouter
    ],
    menuList: [],
    tagsList: [...otherRouter.children],
    pageOpenedList: [],
    currentPageName: '',
    currentPath: [],
    openedSubmenuArr: [],
    menuTheme: '',
    theme: '',
    cachePage: [],
    lang: '',
    isFullScreen: false,
    dontCache: [],
    uploadUrl:'/teaCircleParty/api/upload/unCheck'
};

export default state;

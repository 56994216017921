<template>
  <div class="m-uploader">
    <ul class="file-list" v-if="type === 'many'">
      <li
        class="file-item"
        v-for="(item, index) in list"
        :key="item.uid"
        @focus="onFocus"
        @mouseenter="onHover(index)"
        @mouseleave="onMouseLeave()"
        v-dragging="{ list: list, item: item, group: 'item' }"
      >
        <img class="file-img" :src="item.url" />
        <div class="edit-mask" v-if="editIndex === index">
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(item)"
            >
              <i class="el-icon-zoom-in" v-if="!item.url.includes('.mp4')"></i>
              <i class="el-icon-video-play" v-else></i>
            </span>
            <i
              class="el-icon-delete"
              style="color: #fff; margin-left: 10px"
              @click="handleRemove(item)"
            ></i>
          </span>
        </div>
      </li>
    </ul>
    <ul class="file-list" v-else>
      <li
        class="file-item"
        @focus="onFocus"
        @mouseenter="onHover(0)"
        @mouseleave="onMouseLeave()"
        v-if="file"
      >
        <img class="file-img" :src="file" />
        <div class="edit-mask" v-if="editIndex === 0">
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview({ url: file })"
            >
              <i class="el-icon-zoom-in" v-if="!file.includes('.mp4')"></i>
              <i class="el-icon-video-play" v-else></i>
            </span>
            <i
              class="el-icon-delete"
              style="color: #fff; margin-left: 10px"
              @click="handleRemove({ url: file })"
            ></i>
          </span>
        </div>
      </li>
    </ul>
    <el-upload
      :show-file-list="false"
      :action="uploadUrl"
      :file-list="type === 'single' ? (file ? [{ url: file }] : []) : fileList"
      :limit="limit"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-exceed="handleExceed"
      style="margin-bottom: 8px"
    >
      <!-- <i slot="default" class="el-icon-plus"></i> -->
      <div slot="default" class="uploader-default">
        <i class="el-icon-picture-outline"></i>
        <span style="font-size: 12px；    line-height: 22px;">上传图片</span>
      </div>
      <div slot="tip" class="el-upload__tip" v-if="tip">{{ tip }}</div>
      <div slot="file" slot-scope="{ file }">
        <img
          class="el-upload-list__item-thumbnail"
          :src="file.url"
          v-if="!file.url.includes('.mp4')"
        />
        <div style="position: relative" v-else>
          <el-tag type="success" style="position: absolute">视频</el-tag>
          <video
            :controls="true"
            class="el-upload-list__item-thumbnail"
            :src="file.url"
          ></video>
        </div>
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in" v-if="!file.url.includes('.mp4')"></i>
            <i class="el-icon-video-play" v-else></i>
          </span>
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :append-to-body="true" :visible.sync="dialogVisible">
      <img
        width="100%"
        :src="dialogImageUrl"
        alt=""
        v-if="dialogImageUrl.indexOf('.mp4') === -1"
      />
      <video :controls="true" width="100%" :src="dialogImageUrl" alt="" v-else />
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    //多张图片的字段
    fileList: {
      type: Array,
      default() {
        return [];
      },
    },
    //单张图片的字段
    file: {
      type: String,
      default: "",
    },
    //图片类型，single单张，many多张
    type: {
      type: String,
      default: "many",
    },
    //上传限制数量，默认不限制
    limit: [Number, String],
    //提示
    tip: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      editIndex: "",
      list: [],
      isFirst: true,
    };
  },
  computed: {
    uploadUrl() {
      return this.$store.state.uploadUrl;
    },
    initFileList() {
      let list =
        this.type === "single"
          ? [{ url: this.file }]
          : JSON.parse(JSON.stringify(this.fileList));
      return list;
    },
  },
  mounted() {
    this.$dragging.$on("dragged", ({ value }) => {
      let list = JSON.parse(JSON.stringify(value.list));
      this.$emit("update:fileList", list);
    });
  },
  watch: {
    fileList: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal.length > 0 && (!oldVal || oldVal.length === 0) && this.isFirst) {
          this.$nextTick(() => {
            this.list = this.$tools.deepClone(this.fileList);
          });
          this.isFirst = false;
        }
      },
    },
  },

  methods: {
    handleRemove(file, fileList) {
      if (this.type === "single") {
        this.$emit("update:file", "");
      } else {
        let list = JSON.parse(JSON.stringify(this.fileList));
        let index = list.findIndex((item) => item.url === file.url);
        list.splice(index, 1);
        this.list = this.$tools.deepClone(list);
        this.$emit("update:fileList", list);
      }
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess(response, file, fileList) {
      this.$emit("success", response.data);
      if (this.type === "single") {
        this.$emit("update:file", response.data);
      } else {
        let list = JSON.parse(JSON.stringify(this.fileList));
        list.push({
          url: response.data,
          status: 1,
        });
        this.list = this.$tools.deepClone(list);
        this.$emit("update:fileList", list);
      }
    },
    handleExceed(files, fileList) {
      this.$message.error(`图片限制最多上传${this.limit}张`);
    },
    onFocus(e) {
      console.log("聚焦", e);
    },
    onHover(index) {
      this.editIndex = index;
      console.log("拖拽");
    },
    onMouseLeave() {
      this.editIndex = "";
    },
  },
};
</script>

<style lang="less" scoped>
.m-uploader {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.file-list {
  display: flex;
  align-items: center;
  .file-item {
    width: 80px;
    height: 80px;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 0 8px 8px 0;
    display: inline-block;
    position: relative;
    .file-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.edit-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-icon-zoom-in,
.el-icon-video-play {
  color: #fff;
}
.uploader-default {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 1px dashed #dddddd;
  border-radius: 4px;
  color: #666666;
}
</style>

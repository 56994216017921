import Axios from '../axios.js';

export const login = (data) =>{
  return Axios({
    url: `/api/admin/user/login`,
    method: 'put',
    isFormData: true,
    data: {
      username: data.username,
      password: data.password
    }
  }).then(
    res => Promise.resolve(res),
    err => Promise.reject(err)
  );
}

export const logout = (data) =>{
  return Axios({
    url: `/api/admin/user/logout`,
    method: 'put'
  }).then(
    res => Promise.resolve(res),
    err => Promise.reject(err)
  );
}

export const getCurrentInfo = (data) =>{
  return Axios({
    url: `/api/admin/user/current`,
    method: 'get'
  }).then(
    res => Promise.resolve(res),
    err => Promise.reject(err)
  );
}

/** 获取用户账号列表 */
export const getUserList = (params) =>{
  return Axios({
    url: `/api/admin/user/not/partyBranch`,
    method: 'get',
    params
  }).then(
      res => Promise.resolve(res),
      err => Promise.reject(err)
  );
}

export const test = () => {
  console.log("hhh");
}

// 在下面写就包含对象引入，以上属于可以解构的
export default {
  login,
  logout,
  getCurrentInfo,
  getUserList,
  test
}
<template>
  <section>
    <el-table
      :data="data"
      :header-row-style="{
        background: '#F7F8FA',
      }"
      :header-cell-style="{
        'font-size': '14px',
        color: '#909399',
        'font-weight': '400',
        background: '#F7F8FA',
      }"
      stripe
      border
      style="width: 100%"
      @selection-change="onSelection"
      @expand-change="onExpand"
    >
      <template v-for="(row, rowIndex) in config">
        <!-- 多选框 -->
        <el-table-column type="selection" v-if="rowIndex == 0 && showCheckbox">
        </el-table-column>
        <!-- 展开行 -->
        <el-table-column type="expand" v-if="rowIndex == 0 && expand">
          <template slot-scope="scope">
            <el-table
              :data="scope.row.children"
              :header-row-style="{
                background: '#F7F8FA',
              }"
              :header-cell-style="{
                'font-size': '14px',
                color: '#909399',
                'font-weight': '400',
                background: '#F7F8FA',
              }"
              stripe
              style="width: 100%"
            >
              <template v-for="(row, rowIndex) in expandConfig">
                <el-table-column
                  :prop="row.property"
                  :label="row.label"
                  :key="rowIndex"
                  
                >
                </el-table-column>
              </template>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          :prop="row.property"
          :label="row.label"
          :key="rowIndex"
          align="center"
          :show-overflow-tooltip="row.showOverflow"
        >
          <template slot="header" slot-scope="scope" >
            <span class="flex_center">{{ row.label }}</span>
            <i
              :class="row.icon"
              @click="row.iconClick"
              v-if="row.icon && row.iconClick"
            ></i>
            <el-tooltip
              class="item"
              effect="light"
              placement="top"
              v-if="row.icon && row.tooltip"
            >
              <i :class="row.icon"></i>
              <div slot="content" class="tooltip-content">
                <div >
                  {{ row.content }}
                </div>
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <!-- 自定义插槽 -->
            <slot :index="rowIndex" :item="scope.row" v-if="row.isSlot"></slot>
            <slot
              name="switch"
              :index="rowIndex"
              :item="scope.row"
              v-if="row.property === 'switch'"
            >
              <el-switch
                v-if="row.property === 'switch'"
                v-model="scope.row.noticedStatus"
                active-value="1"
                inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="启用"
                inactive-text="禁用"
                @click.native.prevent="row.click(scope.row, scope.$index)"
              >
              </el-switch>
            </slot>
            <slot
              name="operating"
              :index="rowIndex"
              :item="scope.row"
              v-if="row.type === 'operating'"
            ></slot>
            <el-image
              style="width: 60px; height: 60px"
              :src="getRealValue(scope.row, row.img)"
              :preview-src-list="[getRealValue(scope.row, row.img)]"
              v-if="row.img"
            > 
            </el-image>
            
            <div
              class="table-content--value showOverTooltip"
            >
              {{ getValue(scope.row, config[rowIndex].property) }}
            </div>
            <div class="button-list">
              <div
                class="button-item"
                v-for="(btn, btnIndex) in config[rowIndex].buttonList"
                :key="btnIndex"
                v-if="
                  btnIndex < (config[rowIndex].buttonList.length === 4 ? 4 : 3)
                "
              >
              <el-button
                  @click.native.prevent="btn.click(scope.row, scope.$index)"
                  type="text"
                  size="small"
                  class="button-item--text"
                  v-permission="btn.permission"
                  v-if="btn.name == '删除'"
                  style="color:#F0232D"
                >
                  {{ btn.name }}
                </el-button>
                <template v-else-if="btn.nameList&&btn.name!='删除'">
                  <el-button
                    @click.native.prevent="sBtn.click(scope.row, scope.$index)"
                    type="text"
                    size="small"
                    class="button-item--text"
                    v-permission="sBtn.permission"
                    v-for="sBtn in btn.nameList"
                    :key="sBtn"
                    v-if="sBtn.if(scope.row, scope.$index)"
                  >
                    {{ sBtn.name }}
                  </el-button>
                </template>
                
                <el-button
                  @click.native.prevent="btn.click(scope.row, scope.$index)"
                  type="text"
                  size="small"
                  class="button-item--text"
                  v-permission="btn.permission"
                  v-else
                >
                  {{ btn.name }}
                </el-button>
              </div>
              <el-popover
                placement="bottom"
                width="120"
                trigger="click"
                v-if="
                  config[rowIndex].buttonList &&
                  config[rowIndex].buttonList.length > 4
                "
              >
                <div>
                  <div
                    v-for="(btn, btnIndex) in config[rowIndex].buttonList"
                    :key="btnIndex"
                  >
                    <template v-if="btnIndex >= 3 && btn.nameList">
                      <el-button
                        @click.native.prevent="
                          sBtn.click(scope.row, scope.$index)
                        "
                        type="text"
                        size="small"
                        class="button-item--text"
                        v-permission="sBtn.permission"
                        v-for="sBtn in btn.nameList"
                        :key="sBtn"
                        v-if="sBtn.if(scope.row, scope.$index)"
                      >
                        {{ sBtn.name }}
                      </el-button>
                    </template>
                    <el-button
                      @click.native.prevent="btn.click(scope.row, scope.$index)"
                      type="text"
                      size="small"
                      class="button-item--text"
                      v-permission="btn.permission"
                      v-if="btnIndex >= 3 && !btn.nameList"
                    >
                      {{ btn.name }}
                    </el-button>
                  </div>
                </div>
                <div class="button-item" slot="reference">
                  <el-button class="button-item--text" type="text" size="small"
                    >更多</el-button
                  >
                  <i style="color: #0084ff" class="el-icon-arrow-down"></i>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div v-if="data.length && total > 0" class="text-center p-sm">
      <el-pagination
        background
        @current-change="getList"
        :current-page.sync="search.page"
        :page-size="search.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isAllChoose: false,
      isIndeterminate: false,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    config: {
      type: Array,
      required: true,
    },
    search: {
      type: Object,
      default: () => {
        return {};
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    showOverflow: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    // expandData: {
    //   type: Array,
    //   required: true,
    // },
    expandConfig: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    pureLabel: function () {
      let list = [];
      this.config.forEach((item) => {
        if (!item.type) {
          list.push(item);
        }
      });
      return list;
    },
  },
  mounted() {
    console.log("config", this.data);
  },
  methods: {
    allCheckbox(isAllChoose) {
      const that = this;
      that.isIndeterminate = false;
      that.data.map((item) => {
        item.isChoose = isAllChoose;
      });
    },
    onIconTap(i) {
      this.$emit("on-icon-tap", i);
    },
    singleCheckbox() {
      const that = this;
      let len = that.data.length;
      let sum = 0;
      that.data.map((item) => {
        if (item.isChoose) {
          sum++;
        }
      });
      that.isAllChoose = len === sum;
      that.isIndeterminate = !!(sum && len !== sum);
    },
    choosePropertyTap(obj) {
      this.$emit("on-name", obj);
    },
    iconTap(obj) {
      this.$emit("on-icon", obj);
    },
    onSelection(obj) {
      this.$emit("on-selection", obj);
    },
    onExpand(obj, e) {
      console.log(obj, "obj");
      this.$emit("on-expand", obj, e);
    },
    getRealValue(obj, property) {
      let value = obj;
      let propertyList = property.split(".");
      propertyList.forEach((name) => {
        if (value) {
          value = value[name];
        }
      });
      if (typeof value === "boolean") {
        return value ? "是" : "否";
      } else {
        return value;
      }
    },
    touchS(item, pro) {
      console.log(item, "item");
      console.log(pro, "pro");
    },
    getValue(obj, property) {
      let value = obj;
      if (String(property).indexOf(".") != -1) {
        let propertyList = property.split(".");
        propertyList.forEach((name) => {
          if (value) {
            value = value[name];
          }
        });
      } else {
        value = value[property];
      }
      return value;
    },
    swicth(obj, index) {},
    getSrcList(obj, property) {
      let value = obj;
      let propertyList = property.split(".");
      propertyList.forEach((name) => {
        if (value) {
          value = value[name];
        }
      });
      return [value];
    },

    getList(e) {
      console.log("page", e);
      this.$emit("onPageChange", e);
    },
  },
};
</script>

<style scoped lang="less">
.text-flow-ellipsis-multiple {
  /* 多余内容省略号处理-多行 */
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.table-tr {
  display: flex;
  background-color: white;
  padding: 12px 10px;
  font-size: 14px;
  flex-wrap: nowrap;
  overflow-x: auto;
  align-items: center;
  border: 1px solid var(--border-color);
  border-bottom: none;
}

.table-tr:last-of-type {
  border-bottom: 1px solid var(--border-color);
  border-radius: 0 0 5px 5px;
}

.table-tr:first-of-type {
  border-radius: 5px 5px 0 0;
}

.table-tr:nth-child(even) {
  /*background-color: #f9f9f9;*/
}

.table-tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.table-th {
  flex: 1 1 15%;
  font-weight: bolder;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-td {
  flex: 1 1 15%;
  text-align: center;
  margin-right: 10px;
  max-height: 105px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.table-td img {
  width: 60px;
  display: block;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .hide-pc {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }

  .table-tr {
    border: 1px solid rgba(187, 187, 187, 0.5);
    padding: 0 20px;
    border-radius: 4px;
    margin: 15px 0;
    flex-wrap: wrap;
  }

  .table-td {
    text-align: right;
    padding: 15px;
    flex: 1 0 100%;
  }

  .table-td_label {
    float: left;
    font-weight: bolder;
  }
}
.table-header {
  background: #f7f8fa;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
}

.table-header--title {
  font-size: 14px;
  color: #909399;
}

.button-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: 5px;
}

.button-item {
  width: 60px;
}

.el-button + .el-button {
  margin-left: 0;
}

.table-content--value {
  font-size: 14px;
  font-weight: 400;
  color: #606266;
}

.button-item--text {
  font-weight: 400;
  color: #0084ff;
  font-size: 14px;
}
.showOverTooltip{
  display:-webkit-box;
  text-overflow:ellipsis;
  overflow:hidden;
    /*这里是3行*/
  -webkit-line-clamp: 3;
  -webkit-box-orient:vertical;
}
.tooltip-content {
  div {
    line-height: 18px;
  }
  div:nth-child(3) {
    padding: 5px 0;
  }
  .indent {
    text-indent: 20px;
  }
}
</style>

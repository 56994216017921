function deepClone(value, hashMap = new WeakMap()) { //弱应用下次垃圾回收机制执行时，这块内存会被释放， 解决循环引用情况， 另辟一个存储空间，
    if (value == null || typeof value !== 'object') {
        return value;
    }
    if (value instanceof Date) {
        return new Date(value)
    }
    if (value instanceof RegExp) {
        return new RegExp(value)
    }
    let obj = new value.constructor; //兼容数组和对象
    let v = hashMap.get(value);
    if (v) {
        return v;
    }
    hashMap.set(value, obj)
    for (let key in value) {
        if (value.hasOwnProperty(key)) {
            obj[key] = deepClone(value[key], hashMap)
        }
    }
    return obj
}

/**
 * 通过链接下载文件
 * @param {String} url 链接
 * @param {String} fileName 文件名
 */
function downloadFileUrl(url, fileName) {
    const elink = document.createElement('a')

    elink.href = url

    elink.setAttribute('download', fileName)

    elink.style.display = 'none'

    document.body.appendChild(elink)

    setTimeout(() => {

        elink.click()

        document.body.removeChild(elink)

    }, 66)
}

function unique(arr) {
    let hash = {};
    const newArr = arr.reduce((preVal, curVal) => {
        hash[curVal.id] ? '' : hash[curVal.id] = true && preVal.push(curVal);
        return preVal
    }, [])
    return newArr
}

module.exports = {
    deepClone,
    downloadFileUrl,
    unique
}


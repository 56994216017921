export const convertBase64UrlToBlob = function (dataUrl) {
  let bytes = window.atob(dataUrl.split(',')[1]);
  let ab = new Uint8Array(bytes.length);
  for (let i = 0; i < bytes.length; i++) {
    ab[i] = bytes.charCodeAt(i);
  }
  return new Blob([ab], {type: 'image/png'});
};


export const dateFormat = function (time, flag = false) {
  let date = new Date(time);
  let str = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  if (!flag) {
    return str;
  }
  return `${str} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

};

export const getDateDiff = (dateTimeStamp) => {
  var result;
  var minute = 1000 * 60;
  var hour = minute * 60;
  var day = hour * 24;
  var halfamonth = day * 15;
  var month = day * 30;

  var d = new Date(dateTimeStamp); //根据时间戳生成的时间对象
  var g_year = d.getFullYear();
  var g_month = d.getMonth() + 1;
  var g_day = d.getDate();

  if (g_month < 10) {
    g_month = "0" + g_month;
  }
  if (g_day < 10) {
    g_day = "0" + g_day;
  }

  var y_date = g_year.toString().substring(2) + "-" + g_month + "-" + g_day;
  var d_date = g_month + "-" + g_day;

  var now = new Date().getTime();
  var diffValue = now - dateTimeStamp;
  if (diffValue < 0) {
    return;
  }
  var monthC = diffValue / month;
  var weekC = diffValue / (7 * day);
  var dayC = diffValue / day;
  var hourC = diffValue / hour;
  var minC = diffValue / minute;
  if (monthC >= 1) {
    if (monthC <= 12)
      /*  result = "" + parseInt(monthC) + "月前"; */
      result = d_date;
    else {
      /* result = "" + parseInt(monthC / 12) + "年前"; */
      result = y_date;
    }
  } else if (weekC >= 1) {
    /* result = "" + parseInt(weekC) + "周前"; */
    result = d_date;
  } else if (dayC >= 1) {
    if (dayC >= 4) {
      result = d_date;
    } else {
      result = "" + parseInt(dayC) + "天前";
    }
  } else if (hourC >= 1) {
    result = "" + parseInt(hourC) + "小时前";
  } else if (minC >= 1) {
    result = "" + parseInt(minC) + "分钟前";
  } else {
    result = "刚刚";
  }

  return result;
};

export const formatTime = (date, flag = true) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  if (flag) {
    return (
      [year, month, day].map(formatNumber).join("-") +
      " " +
      [hour, minute, second].map(formatNumber).join(":")
    );
  }
  return [year, month, day].map(formatNumber).join("-");
};

function formatNumber(n) {
  n = n.toString();
  return n[1] ? n : "0" + n;
}
<template>
  <div class="app-main">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
}
</script>

<style>
.app-main {
  height: 100vh;
}
</style>

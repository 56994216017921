
const permission = {
    inserted: function (el, binding) {
        // 获取页面按钮权限
        let btnPermissionsArr = [];
        if(binding.value){
            // 如果指令传值，获取指令参数，根据指令参数和当前登录人按钮权限做比较。
            btnPermissionsArr = Array.of(binding.value);
        }else{
            // 否则获取路由中的参数，根据路由的btnPermissionsArr和当前登录人按钮权限做比较。
            btnPermissionsArr = JSON.parse(localStorage.getItem('zhaozhaocha_button_permission'))
        }
        // console.log(el)
        // console.log(el.parentNode)
        if (!hasPermission(btnPermissionsArr)) {
            el.parentNode.removeChild(el);
        }
    }
}
// 权限检查方法
function hasPermission (value) {
    let isExist = false;
    // 获取用户按钮权限
    let btnPermissionsStr = JSON.parse(localStorage.getItem("zhaozhaocha_button_permission"));
    if (btnPermissionsStr == undefined || btnPermissionsStr == null) {
        return false;
    }
    // console.log('存在吗？', btnPermissionsStr.indexOf(value[0]))
    if (btnPermissionsStr.indexOf(value[0]) > -1) {
        isExist = true;
    }
    return isExist;
};

export default permission

<style lang="less">
@import "../main.less";
</style>

<template>
  <div ref="scrollCon" @mousewheel="handlescroll" class="tags-outer-scroll-con">
    <div
      ref="scrollBody"
      class="tags-inner-scroll-body"
      :style="{ left: tagBodyLeft + 'px' }"
    >
      <transition-group name="taglist-moving-animation">
        <template v-if="pageTagsList.length">
          <Tag
            type="dot"
            v-for="item in pageTagsList"
            ref="tagsPageOpened"
            :key="item.name"
            :name="item.name"
            @on-close="closePage"
            @click.native="linkTo(item)"
            :closable="item.name === 'tea-account-list' ? false : true"
            :color="
              item.children
                ? item.children[0].name === currentPageName
                  ? 'blue'
                  : 'default'
                : item.name === currentPageName
                ? 'blue'
                : 'default'
            "
          >
            {{ itemTitle(item) }}
          </Tag>
        </template>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "tagsPageOpened",
  data() {
    return {
      currentPageName: this.$route.name,
      tagBodyLeft: 0,
      currentScrollBodyWidth: 0,
      refsTag: [],
      tagsCount: 1,
    };
  },
  props: {
    pageTagsList: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    console.log(this.pageTagsList);
    if (!this.pageTagsList.length ) return;
    if (!this.pageTagsList[0]) {
        this.pageTagsList.shift()
    }
    for (let i = 0; i < this.pageTagsList.length; i++) {
      let item = this.pageTagsList[i];
      if (item.name === "home_index") {
        this.pageTagsList.splice(i, 1);
        break;
      }
    }
  },
  computed: {
    title() {
      return this.$store.state.currentTitle;
    },
    tagsList() {
      return this.$store.state.pageOpenedList;
    },
  },
  methods: {
    itemTitle(item) {
      if (typeof item.title === "object") {
        return item.title;
      } else {
        return item.title;
      }
    },
    closePage(event, name) {
      this.$store.commit("removeTag", name);
      this.$store.commit("closePage", name);
      if (this.currentPageName === name) {
        let lastPageName = "";
        if (this.$store.state.pageOpenedList.length > 1) {
          lastPageName = this.$store.state.pageOpenedList[1].name;
        } else {
          lastPageName = this.$store.state.pageOpenedList[0].name;
        }
        this.$router.push({
          name: lastPageName,
        });
      }
    },
    linkTo(item) {
      let routerObj = {};
      routerObj.name = item.name;
      if (item.argu) {
        routerObj.params = item.argu;
      }
      if (item.query) {
        routerObj.query = item.query;
      }
      this.$router.push(routerObj);
    },
    handlescroll(e) {
      let left = 0;
      if (e.wheelDelta > 0) {
        left = Math.min(0, this.tagBodyLeft + e.wheelDelta);
      } else {
        if (
          this.$refs.scrollCon.offsetWidth - 100 <
          this.$refs.scrollBody.offsetWidth
        ) {
          if (
            this.tagBodyLeft <
            -(
              this.$refs.scrollBody.offsetWidth -
              this.$refs.scrollCon.offsetWidth +
              100
            )
          ) {
            left = this.tagBodyLeft;
          } else {
            left = Math.max(
              this.tagBodyLeft + e.wheelDelta,
              this.$refs.scrollCon.offsetWidth -
                this.$refs.scrollBody.offsetWidth -
                100
            );
          }
        } else {
          this.tagBodyLeft = 0;
        }
      }
      this.tagBodyLeft = left;
    },
    handleTagsOption(type) {
      if (type === "clearAll") {
        this.$store.commit("clearAllTags");
      } else {
        this.$store.commit("clearOtherTags", this);
      }
      this.tagBodyLeft = 0;
    },
    moveToView(tag) {
      if (tag.offsetLeft < -this.tagBodyLeft) {
        // 标签在可视区域左侧
        this.tagBodyLeft = -tag.offsetLeft + 10;
      } else if (
        tag.offsetLeft + 10 > -this.tagBodyLeft &&
        tag.offsetLeft + tag.offsetWidth <
          -this.tagBodyLeft + this.$refs.scrollCon.offsetWidth - 100
      ) {
        // 标签在可视区域
      } else {
        // 标签在可视区域右侧
        this.tagBodyLeft = -(
          tag.offsetLeft -
          (this.$refs.scrollCon.offsetWidth - 100 - tag.offsetWidth) +
          20
        );
      }
    },
  },

  watch: {
    $route(to) {
      this.currentPageName = to.name;
      this.$nextTick(() => {
        this.refsTag.forEach((item, index) => {
          if (to.name === item.name) {
            let tag = this.refsTag[index].$el;
            this.moveToView(tag);
          }
        });
      });
      this.tagsCount = this.tagsList.length;
    },
  },
};
</script>
